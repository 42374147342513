import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { SnackbarProvider } from 'notistack';
import App from './App';
import { persistor, store } from "./redux/store"
import Modal from 'react-modal';

Modal.setAppElement('#root');

const container = document.getElementById('root');
const root = createRoot(container); // Create a root
root.render(
  <SnackbarProvider
    maxSnack={3}
    autoHideDuration={2000}
    preventDuplicate
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
  >
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </SnackbarProvider>
); 
