import axios from 'axios'
import { base_url } from '../../utils/base_url'
import { config } from '../../utils/axiosconfig';
const login = async (userData) => {
    const response = await axios.post(`${base_url}user/admin-login`, userData)
    if (response.data) {
        localStorage.setItem("user", JSON.stringify(response.data))
    }
    return response.data
};

const logout = async () => {
    const reponse = await axios.get(`${base_url}user/logout`)
    return reponse.data
}
const allOrders = async () => {
    const configure = config()
    const response = await axios.get(`${base_url}user/getallorders`, configure)
    return response.data

}

const authService = {
    login,
    allOrders,
    logout,
};
export default authService