import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Rating from 'react-rating-stars-component';
import { IoShareOutline, IoTimer } from 'react-icons/io5';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { FaCheckCircle } from 'react-icons/fa';
import StrainLower from '../components/StrainDetail/StrainLower';
import Meta from '../components/SEO/Meta';
import { useDispatch, useSelector } from 'react-redux';
import SocialShareModal from '../components/StrainDetail/SocialShare';
import StrainPopup from '../components/StrainDetail/StrainPopup';
import { CiCirclePlus } from 'react-icons/ci';
import { FaLink } from 'react-icons/fa6';
import FolderSelectionModal from '../components/StrainDetail/FolderSelectionModal';
import 'react-toastify/dist/ReactToastify.css';
import Products from '../components/products/Products';
import {
  effectsList,
  flavorsList,
} from '../components/CommonComponent/CommonData/CommonData';
import { enqueueSnackbar } from 'notistack';
import BussinessCard from '../components/CommonComponent/AddBusinesses/BussinessCard';
import StrainSinglePageLoader from '../skeleton/StrainSinglePageLoader';
import { getAllFolders } from '../features/stashFolder/folderSlice';
import { addStrainToFolder } from '../features/stashFolder/folderService';
import { getUrl } from '../utils/GetBussinessUrl';
import ReviewList from '../components/productDetails/ReviewList';
import { useGetStrainsByIdQuery } from '../redux/api/strainApi';
import { format } from 'date-fns';
import StrainCard from '@/common-components/strain/StrainCard';
import placeholderImage from '@/Images/placeholderImage.png';
const Modal = ({ isOpen, onClose, children, title, strainimg }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-opacity-75 flex justify-center items-center z-30">
      <div className="bg-[#2c2c2c] rounded-lg shadow-xl p-4 m-4 w-80 md:w-85 max-h-full overflow-auto">
        <div className="flex justify-between items-center">
          <div className="flex justify-start items-center space-x-2">
            <img
              src={strainimg}
              alt={title}
              className="h-10 w-10 object-contain rounded-md"
            />
            <h2 className="text-white text-md font-semibold">{title}</h2>
          </div>
          <button
            onClick={onClose}
            className="text-gray-600 hover:text-gray-900"
          >
            <svg
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        {children}
      </div>
    </div>
  );
};
const StrainPages = () => {
  // hooks
  const { id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  let navigate = useNavigate();

  // global State
  const isUser = useSelector((state) => state.auth.user?._id);
  const folders = useSelector((state) => state.folder.folders);

  // local State

  const [isUpdate, setisUpdate] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [menuModalOpen, setMenuModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('Breeder');
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const tabsRef = useRef(null);
  const [isFolderModalOpen, setIsFolderModalOpen] = useState(false);

  // useRefrence
  const breederRef = useRef(null);
  const genealogyRef = useRef(null);
  const aboutRef = useRef(null);
  const variantsRef = useRef(null);
  const reviewsRef = useRef(null);
  const shopRef = useRef(null);

  // Handle tab click
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    const refs = {
      Breeder: breederRef,
      Genealogy: genealogyRef,
      About: aboutRef,
      Variants: variantsRef,
      Reviews: reviewsRef,
      Shop: shopRef,
    };
    scrollTo(refs[tabName]);
  };

  // Scroll to a section smoothly
  const scrollTo = (ref) => {
    if (ref.current) {
      const headerHeight = 80; // Height of the header
      const tabsHeight = tabsRef.current ? tabsRef.current.offsetHeight : 0; // Height of the tabs section
      const offsetTop = ref.current.offsetTop - headerHeight - tabsHeight; // Adjust the offset

      window.scrollTo({
        top: offsetTop,
        behavior: 'smooth',
      });
    }
  };

  // Update active tab based on scroll position
  useEffect(() => {
    const handleScroll = () => {
      const headerHeight = 80; // Height of the header
      const tabsHeight = tabsRef.current ? tabsRef.current.offsetHeight : 0; // Height of the tabs section

      const sections = {
        Breeder: breederRef.current,
        Genealogy: genealogyRef.current,
        About: aboutRef.current,
        Variants: variantsRef.current,
        Reviews: reviewsRef.current,
        Shop: shopRef.current,
      };

      for (const section in sections) {
        const currentSection = sections[section];
        if (currentSection) {
          const sectionTop = currentSection.offsetTop;
          const sectionBottom = sectionTop + currentSection.offsetHeight;
          const scrollPosition = window.scrollY + headerHeight + tabsHeight;

          if (scrollPosition >= sectionTop && scrollPosition <= sectionBottom) {
            setActiveTab(section);
            break;
          }
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const { data: strainData, error, isLoading } = useGetStrainsByIdQuery(id);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error?.message, { variant: 'error' });
    }
  }, [error]);

  // fetch data single api
  useLayoutEffect(() => {
    let isSubscribe = true;
    if (isSubscribe) {
      dispatch(getAllFolders());
    }
  }, [dispatch, id, isUpdate]);

  useEffect(() => {
    if (window.location.hash !== '#Reviews') {
      const timer = setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100); // 100 milliseconds delay

      return () => clearTimeout(timer);
    }
  }, [location.pathname, location.search]);

  useEffect(() => {
    const hash = window.location.hash.replace('#', '');
    setActiveTab(hash || 'Breeder');
    // eslint-disable-next-line
  }, [window.location.hash]);

  const productsByCategory = strainData?.products.reduce((acc, product) => {
    const category = product.category;
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(product);
    return acc;
  }, {});

  const isAddedInFolder = useMemo(() => {
    for (let folder of folders) {
      if (folder.strains?.find((item) => item?._id === id)) {
        return true;
      }
    }
    return false;
  }, [id, folders]);

  const handleAddToStash = async () => {
    const likedStrainsFolder = folders?.find(
      (folder) => folder.name === 'Liked Strains'
    );
    try {
      let Data = {
        itemId: id,
        type: 'strain',
      };

      const res = await addStrainToFolder(likedStrainsFolder._id, Data);
      if (res) {
        setisUpdate(!isUpdate);
        enqueueSnackbar(res?.message, { variant: 'success' });
      }
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: 'error' });
    }
  };

  const shareUrl = `https://maryapp.io/strain/`;

  const handleReviewDetailOpen = (strainId) => {
    if (isUser) {
      navigate(`/strain/${strainId}/review-detail`, {
        state: { returnPath: `/strain/${strainId}` },
      });
    } else {
      setIsPopupOpen(true);
    }
  };

  const toggleFolderModal = () => {
    setIsFolderModalOpen(true);
  };

  const renderIcon = () => {
    if (isAddedInFolder) {
      return (
        <FaCheckCircle
          size={28}
          className="text-[#62AF41]"
          onClick={toggleFolderModal}
        />
      );
    } else {
      return (
        <CiCirclePlus
          size={28}
          className="text-white"
          onClick={handleAddToStash}
        />
      );
    }
  };
  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const calculateAverageRating = (reviews) => {
    if (reviews?.length === 0) return 0;
    const total = reviews?.reduce((acc, review) => acc + review?.rating, 0);
    return total / reviews?.length;
  };

  function linkify(text) {
    const urlPattern = /(https?:\/\/[^\s]+|www\.[^\s]+|example\.[^\s]+)/g;
    return text?.replace(urlPattern, (url) => {
      const hyperlink = url.startsWith('http') ? url : `http://${url}`;
      return `<a href="${hyperlink}" target="_blank" rel="noopener noreferrer", style="color: #62AF41;">${url}</a>`;
    });
  }

  const TreepleDotToggl = () => setMenuModalOpen(!menuModalOpen);

  const handleUpdate = () => {
    setisUpdate(!isUpdate);
  };

  const averageRating = calculateAverageRating(strainData?.reviews);
  const linkedDescription = linkify(strainData?.strain?.description);

  return (
    <div key={strainData?.strain?._id} className=" px-4 pb-20 ">
      <Meta title={strainData?.strain?.title} siteLink={'/Galeto'} />
      {isLoading && <StrainSinglePageLoader />}
      {!isLoading && (
        <>
          <div className=" flex flex-col gap-4 ">
            <div className="flex flex-col justify-start items-start ">
              <span className="text-xs text-white">
                {format(new Date(strainData?.strain?.createdAt), 'yyyy-MM-dd')}
              </span>
              <h1 className="text-white text-2xl font-semibold md:text-3xl">
                {strainData?.strain?.title}
              </h1>
              <span className="text-xs text-white font-medium">{`${
                strainData?.strain?.mother?.title ?? 'Not Found'
              } x ${strainData?.strain?.father?.title ?? 'Not Found'}`}</span>
              <span className="text-xs text-white">
                {strainData?.strain?.type}
              </span>
            </div>
            <div
              style={{ objectFit: 'contain' }}
              className="h-83  md:h-[500px] "
            >
              <img
                src={strainData?.strain?.strainImg?.url}
                className="w-full h-full"
                alt={strainData?.strain?.title}
              />
            </div>

            <div className="flex justify-between w-full">
              <div className="flex flex-col justify-start  text-white">
                <Rating
                  count={5}
                  size={20}
                  activeColor="#FFFFFF"
                  value={averageRating}
                  edit={false}
                  className="bg-[#253238]"
                />
                <span className="text-xs">
                  {strainData?.reviews?.length} Reviews
                </span>
              </div>
              <div className="flex justify-start items-center text-white space-x-2">
                <span onClick={toggleModal}>
                  <IoShareOutline size={24} className="cursor-pointer" />
                </span>
                <span className="cursor-pointer">{renderIcon()}</span>
                {folders && (
                  <FolderSelectionModal
                    itemType="strain"
                    itemId={id}
                    backBtnText="Back to Strain"
                    isOpen={isFolderModalOpen}
                    onClose={() => setIsFolderModalOpen(false)}
                    onUpdate={() => setisUpdate(!isUpdate)}
                  />
                )}
                <span onClick={TreepleDotToggl}>
                  <BsThreeDotsVertical size={24} className="cursor-pointer" />
                </span>
                {isModalOpen && (
                  <SocialShareModal
                    isOpen={isModalOpen}
                    url={`${shareUrl}${strainData?.strain._id}`}
                    title={strainData?.strain.title}
                    strainImg={strainData?.strain.strainImg.url}
                    type={strainData?.strain.type}
                    onClose={toggleModal}
                    des={'Share this strain'}
                  />
                )}
                <Modal
                  isOpen={menuModalOpen}
                  onClose={TreepleDotToggl}
                  title={strainData?.strain?.title}
                  strainimg={strainData?.strain?.strainImg?.url}
                >
                  <div className="flex flex-col justify-start items-start gap-2 pt-8">
                    <div className="flex justify-start items-center space-x-2">
                      <span className="cursor-pointer">
                        {renderIcon(strainData?.strain?._id)}
                      </span>
                      <span className="flex justify-start items-center text-gray-300">
                        Add to Stash
                      </span>
                    </div>

                    <div
                      onClick={() =>
                        navigate(
                          `${getUrl(
                            strainData?.strain?.business?.id?.PrimaryBussiness,
                            strainData?.strain?.business?.id?._id
                          )}`
                        )
                      }
                      className="flex justify-start items-center space-x-2"
                    >
                      <span className="cursor-pointer">
                        <FaLink size={28} className="rounded-full text-white" />
                      </span>
                      <span className="flex justify-start items-center text-gray-300">
                        Go to Business
                      </span>
                    </div>

                    <div className="flex justify-start items-center space-x-2">
                      <span className="cursor-pointer">
                        {
                          <FaLink
                            size={28}
                            className="rounded-full"
                            onClick={() =>
                              navigate(`/strain/${strainData?.strain?._id}`)
                            }
                          />
                        }
                      </span>
                      <span className="flex justify-start items-center text-gray-300">
                        Go to Strain
                      </span>
                    </div>

                    <div className="flex justify-start items-center space-x-2">
                      <span onClick={toggleModal} className="cursor-pointer">
                        {
                          <IoShareOutline
                            size={24}
                            className="cursor-pointer"
                          />
                        }
                      </span>
                      <span className="flex justify-start items-center text-gray-300">
                        Share
                      </span>
                      {isModalOpen && (
                        <SocialShareModal
                          isOpen={isModalOpen}
                          url={`${shareUrl}${strainData?.strain?._id}`}
                          title={strainData?.strain?.title}
                          strainImg={strainData?.strain?.strainImg?.url}
                          type={strainData?.strain?.type}
                          onClose={toggleModal}
                          des={'Share this strain'}
                        />
                      )}
                    </div>
                    <div
                      onClick={() =>
                        navigate(
                          `/sessions-review?type=Strain&id=${strainData?.strain?._id}`
                        )
                      }
                      className="flex justify-start items-center space-x-2  cursor-cell"
                    >
                      <div className="flex justify-start items-center">
                        <IoTimer size={24} className="text-white" />
                      </div>
                      <span className="flex justify-start items-center text-gray-300">
                        Add session
                      </span>
                    </div>
                  </div>
                </Modal>
              </div>
            </div>
          </div>

          <div className="flex flex-col items-start justify-start ">
            <div className="flex  items-center w-full sticky top-[50px] lg:top-[61px] z-10 bg-[#0f0f0f] py-4 ">
              <div className="flex justify-start items-start lg:ml-6 overflow-x-auto scrollbar-hide">
                <ul className="flex justify-start items-start gap-3">
                  {[
                    'Breeder',
                    'Genealogy',
                    'About',
                    'Reviews',
                    'Shop',
                    'Variants',
                  ]?.map((tab) => (
                    <li
                      key={tab}
                      className={`  cursor-pointer text-white lg:text-lg ${
                        activeTab === tab
                          ? 'border-b-4 border-primary pb-2 font-bold'
                          : 'border-none'
                      }`}
                      onClick={() => handleTabClick(tab)}
                    >
                      {tab}
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div
              ref={breederRef}
              className="flex justify-start items-start text-white text-2xl px-2 w-full"
            >
              Breeder
            </div>
            <div className=" w-full py-2">
              <BussinessCard
                loading={false}
                Data={strainData?.strain?.business?.id}
                isUpdate={false}
                OnUpdate={() => handleUpdate()}
              />
            </div>

            {strainData?.strain?.collabBusiness && (
              <>
                <div className="flex justify-start items-start text-white text-2xl px-2 w-full">
                  Collabrative Breeder
                </div>
                <div className=" w-full py-2">
                  <BussinessCard
                    loading={false}
                    Data={strainData?.strain?.collabBusiness?.id}
                    isUpdate={false}
                    OnUpdate={() => handleUpdate()}
                  />
                </div>
              </>
            )}

            <div
              ref={genealogyRef}
              className="flex justify-start items-start text-white text-2xl py-2"
            >
              Genealogy
            </div>
            <div className="grid grid-cols-2  gap-4">
              <div className="flex flex-col justify-start items-center ">
                <div className="flex justify-center items-center text-white py-2">
                  Mother
                </div>

                {strainData?.strain?.mother ? (
                  <StrainCard
                    itemType="strain"
                    key={strainData?.strain?.mother?._id}
                    id={strainData?.strain?.mother?._id}
                    title={strainData?.strain?.mother?.title}
                    father={
                      strainData?.strain?.mother?.father?.title ?? 'Not Found'
                    }
                    mother={
                      strainData?.strain?.mother?.mother?.title ?? 'Not Found'
                    }
                    type={strainData?.strain?.mother?.type}
                    imageUrl={strainData?.strain?.mother?.strainImg.url}
                    business={strainData?.strain?.mother?.business?.id}
                    onUpdate={() => setisUpdate(!isUpdate)}
                  />
                ) : (
                  <StrainCard
                    itemType="strain"
                    key={'Not Found'}
                    id={'Not Found'}
                    title={'Not Found'}
                    father={'Not Found'}
                    mother={'Not Found'}
                    type={'Not Found'}
                    imageUrl={placeholderImage}
                    business={'Not Found'}
                    onUpdate={() => setisUpdate(!isUpdate)}
                  />
                )}
              </div>
              <div className="flex flex-col justify-start items-center ">
                <div className="flex justify-center items-center text-white py-2">
                  Father
                </div>
                {strainData?.strain?.father ? (
                  <StrainCard
                    itemType="strain"
                    key={strainData?.strain?.father?._id}
                    id={strainData?.strain?.father?._id}
                    title={strainData?.strain?.father?.title}
                    father={
                      strainData?.strain?.father?.father?.title
                        ? strainData?.strain?.father?.father?.title
                        : 'Not Found'
                    }
                    mother={
                      strainData?.strain?.father?.mother?.title
                        ? strainData?.strain?.father?.mother?.title
                        : 'Not Found'
                    }
                    type={strainData?.strain?.type}
                    imageUrl={strainData?.strain?.father?.strainImg.url}
                    business={strainData?.strain?.business?.id}
                    onUpdate={() => setisUpdate(!isUpdate)}
                  />
                ) : (
                  <StrainCard
                    itemType="strain"
                    key={'Not Found'}
                    id={'Not Found'}
                    title={'Not Found'}
                    father={'Not Found'}
                    mother={'Not Found'}
                    type={'Not Found'}
                    imageUrl={placeholderImage}
                    business={'Not Found'}
                    onUpdate={() => setisUpdate(!isUpdate)}
                  />
                )}
              </div>
            </div>

            <div
              ref={aboutRef}
              className="flex justify-start items-start text-white text-2xl  py-2"
            >
              About
            </div>
            <div className="flex justify-start items-center   ">
              <p
                className="text-white w-full overflow-hidden text-ellipsis"
                style={{ whiteSpace: 'pre-line' }}
                dangerouslySetInnerHTML={{ __html: linkedDescription }}
              ></p>
            </div>

            <div className="flex flex-col justify-start bg-[#2c2c2c] bg-opacity-50 shadow-md  p-4 rounded-lg w-full my-4">
              <StrainLower
                mainHeading={'Flavor'}
                options={strainData?.strain?.flavors}
                referenceList={flavorsList}
              />
              <StrainLower
                mainHeading={'Feelings'}
                options={strainData?.strain?.effects}
                referenceList={effectsList}
              />
              <StrainLower
                mainHeading={'Helps With'}
                options={strainData?.strain?.helpsWith}
                isLast={true}
              />
            </div>

            <div
              ref={reviewsRef}
              className="flex justify-start items-start text-white text-2xl py-2"
            >
              {} Reviews
            </div>
            <div className="py-2  w-full">
              <div className="flex items-center py-4 text-white">
                <h2 className="text-xl font-semibold">
                  {strainData?.strain?.title} strain reviews
                  <span className="text-lg text-[#62AF41] ml-2">
                    {strainData?.strain?.reviews?.length}
                  </span>
                </h2>
              </div>
              <button
                className=" w-full  bg-white text-black py-2 border-2 border-black rounded-full font-semibold hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-opacity-50"
                onClick={() => handleReviewDetailOpen(strainData?.strain?._id)}
              >
                Write a Review
              </button>
              {/* Review List */}
              {strainData?.strain?.reviews?.length && (
                <div className=" flex flex-col justify-start items-start  py-6">
                  <ReviewList
                    reviews={
                      strainData?.strain?.reviews
                        ? strainData?.strain?.reviews
                        : []
                    }
                    onUpdate={() => setisUpdate(!isUpdate)}
                  />
                </div>
              )}
            </div>

            <div
              ref={shopRef}
              className="flex justify-start items-start text-white text-2xl py-2 w-full"
            >
              Shop
            </div>
            {Object.keys(productsByCategory)?.map((category) => (
              <div key={category} className="md:pt-2">
                <h2 className="text-xl text-gray-300 px-2 md:pl-8">
                  {category}
                </h2>
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 px-2 py-4">
                  {productsByCategory[category]?.map((product) => (
                    <Products
                      key={product._id}
                      itemId={product._id}
                      title={product.title}
                      productImg={product.productImg.url}
                      price={product.price}
                      category={product.category}
                      brandId={product.brand}
                      weight={product.weight}
                      strainId={product.strain}
                      brand={product.brand}
                    />
                  ))}
                </div>
              </div>
            ))}
            {strainData?.products?.length === 0 && (
              <div className="flex justify-start items-center text-gray-500 font-semibold text-xl py-2">
                No products link to this strain
              </div>
            )}

            <div
              ref={variantsRef}
              className="flex justify-start items-start text-white text-2xl py-2 w-full"
            >
              Variants
            </div>
            {strainData?.strain?.varaint ? (
              <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                <StrainCard
                  itemType="strain"
                  key={strainData?.strain?.varaint?._id}
                  id={strainData?.strain?.varaint?._id}
                  title={strainData?.strain?.varaint?.title}
                  father={
                    strainData?.strain?.mother?.father?.title
                      ? strainData?.strain?.mother?.father?.title
                      : 'Not Found'
                  }
                  mother={
                    strainData?.strain?.mother?.mother?.title
                      ? strainData?.strain?.mother?.mother?.title
                      : 'Not Found'
                  }
                  type={strainData?.strain?.varaint?.type}
                  imageUrl={strainData?.strain?.varaint?.strainImg.url}
                  business={strainData?.strain?.varaint?.business?.id}
                  onUpdate={() => setisUpdate(!isUpdate)}
                />
              </div>
            ) : (
              <div className="flex justify-start items-center text-gray-500 font-semibold text-xl py-2">
                No strain link to this strain
              </div>
            )}

            {isPopupOpen && (
              <StrainPopup isOpen={isPopupOpen} onClose={closePopup} />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default StrainPages;
