import { Avatar, AvatarGroup } from '@mui/material';
import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import { useNavigate, useParams } from 'react-router-dom';
import {
  subscribeBrand,
  unSubscribeBrand,
} from '../features/brand/brandService';
import { enqueueSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import JoinPageLoader from '../skeleton/JoinPageLoader';
import SubscribeDropdown from '../components/brands/SubscribeDropdown';
import { getSingleBusiness } from '../features/addBusiness/addBusinessService';

const Join = () => {
  // hooks
  const navigate = useNavigate();
  const { id, businessType } = useParams();

  // global state
  const user = useSelector((state) => state?.auth?.user);

  // local State
  const [loading, setLoading] = useState(true);
  const [isUpdate, setIsUpdate] = useState(false);
  const [subcribeLoader, setsubcribeLoader] = useState(false);
  const [Data, setData] = useState([]);
  const [isJoin, setIsJoin] = useState(false);

  const isSubscribed = useMemo(
    () => Data?.subscribers?.find((item) => item === user._id),
    [Data, user]
  );

  const getData = useCallback(async () => {
    try {
      const res = await getSingleBusiness(businessType, id);
      if (res) {
        setLoading(false);
        setData(res?.business);
      }
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  }, [id, businessType]);

  useLayoutEffect(() => {
    getData(id);

    return () => {};
  }, [getData, id, isUpdate]);

  const handleSubscribe = async () => {
    if (!isSubscribed) {
      setsubcribeLoader(true);
      try {
        const res = await subscribeBrand(Data._id);
        if (res) {
          setIsUpdate(!isUpdate);
          setsubcribeLoader(false);
        }
        enqueueSnackbar(res.message, { variant: 'success' });
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
        setsubcribeLoader(false);
      }
    }
  };

  const handleUnsubscribe = async () => {
    setsubcribeLoader(true);
    try {
      const res = await unSubscribeBrand(Data._id);
      if (res) {
        setIsUpdate(!isUpdate);
        setsubcribeLoader(false);
      }
      enqueueSnackbar(res.message, { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
      setsubcribeLoader(false);
    }
  };
  const notificationSubscribers = Data?.notificationSubscribers;

  return (
    <>
      {loading && <JoinPageLoader />}
      {!loading && (
        <div className="  flex  justify-center p-4  mb-32 w-full md:w-[430px] md:mx-auto  ">
          <div className=" text-white rounded-lg flex flex-col  justify-center gap-4   w-full">
            <div onClick={() => navigate(-1)} className="flex items-center ">
              <IoIosArrowBack
                size={30}
                className="text-gray-400 cursor-pointer"
              />
            </div>

            {/* Subscription Header */}
            <div className="flex flex-col items-center justify-center gap-2 ">
              <AvatarGroup>
                <Avatar
                  sx={{ border: 'none !important', width: 60, height: 60 }}
                  alt="Remy Sharp"
                  src={Data?.profileImg?.url}
                />
                <Avatar
                  sx={{ border: 'none !important', width: 60, height: 60 }}
                  alt="Travis Howard"
                  src={user?.userImg?.url}
                />
              </AvatarGroup>

              <div>
                <h1 className="text-xl font-semibold">
                  {Data?.title} subscription
                </h1>
                <p className="text-gray-400 text-sm">
                  ${Data?.subscriptionPrice} monthly · Cancel anytime
                </p>
              </div>
            </div>

            {/* Subscription Offers */}
            <div className="space-y-4 ">
              <h2 className="font-semibold text-md">
                This creator is offering the following:
              </h2>
              <ul className="list-disc list-inside text-sm text-gray-400  flex flex-col gap-4">
                <li>Monthly reoccurring cost</li>
                <li>Private menu/links</li>
                <li>Early access to drops</li>
                <li>Exclusive deals and discounts</li>
                <li>Members only products/merch drops</li>
                <li>Exclusive merch</li>
              </ul>
            </div>
            <div className=" flex justify-center items-center ">
              <h1 className=" text-2xl font-bold">
                {Data?.subscriptionPrice}$/Month
              </h1>
            </div>

            {/* Manage Button */}
            <div className="flex flex-col justify-center items-center gap-3">
              <button
                onClick={() => setIsJoin(!isJoin)}
                className=" w-full rounded-3xl text-black  bg-mary-theme hover:bg-mary-theme  p-2 text-sm font-semibold "
              >
                {isJoin ? 'Member ' : 'join'}
              </button>
              {!isSubscribed && (
                <SubscribeDropdown
                  loader={subcribeLoader}
                  isSubscribed={isSubscribed}
                  onSubscribe={handleSubscribe}
                  onUnsubscribe={handleUnsubscribe}
                  loggedInUser={user._id}
                  brandId={id}
                  notificationSubscribers={notificationSubscribers}
                  businessType={'brand'}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Join;
