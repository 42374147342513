import React from 'react';

function timeAgo(dateParam) {
  if (!dateParam) {
    return null;
  }

  const date = typeof dateParam === 'object' ? dateParam : new Date(dateParam);
  const today = new Date();
  const seconds = Math.round((today - date) / 1000);
  const minutes = Math.round(seconds / 60);
  const hours = Math.round(minutes / 60);
  const days = Math.round(hours / 24);
  const weeks = Math.round(days / 7);
  const months = Math.round(days / 30);
  const years = Math.round(days / 365);

  if (seconds < 60) {
    return 'just now';
  } else if (minutes === 1) {
    return '1 minute ago';
  } else if (minutes < 60) {
    return `${minutes} minutes ago`;
  } else if (hours === 1) {
    return '1 hour ago';
  } else if (hours < 24) {
    return `${hours} hours ago`;
  } else if (days === 1) {
    return '1 day ago';
  } else if (days < 7) {
    return `${days} days ago`;
  } else if (weeks === 1) {
    return '1 week ago';
  } else if (weeks < 4) {
    return `${weeks} weeks ago`;
  } else if (months === 1) {
    return '1 month ago';
  } else if (months < 12) {
    return `${months} months ago`;
  } else if (years === 1) {
    return '1 year ago';
  } else {
    return `${years} years ago`;
  }
}

const FeedContent = ({ announcements }) => {
  return (
    <div className="px-5 py-2 md:px-20 md:py-4">
      {announcements.map((feed) => (
        <div key={feed._id} className="text-white shadow-md overflow-hidden">
          {/* dummy image for user space */}
          <div className="flex items-center p-3">
          {/* Dummy user image */}
          <img 
            src={feed.feedImg.url} 
            alt="Feed" 
            className="w-10 h-10 rounded-full" 
          />
          {/* Dummy username next to the image */}
          <div className="ml-3 mb-5 font-semibold">William</div>
          <div className="-ml-14 mt-6 text-xs text-gray-300 lg:ml-3 lg:-mt-4">{timeAgo(feed.createdAt)}</div>
          </div>
          {/* Content */}
          <div className="p-4">
            <h3 className="text-sm mb-2 lg:ml-12 lg:-mt-10 lg:text-left lg:w-[45%]">{feed.announcement}</h3>
            {/* <p className="text-sm mb-4">Announcement on: {new Date(feed.createdAt).toLocaleDateString()}</p> */}
          </div>
          {/* Image */}
          <img 
            src={feed.feedImg.url} 
            alt="Feed" 
            className="w-full object-cover lg:w-[45%] lg:ml-14 lg:-mt-3 lg:rounded-xl" 
          />
        </div>
      ))}
    </div>
  );
};

export default FeedContent;