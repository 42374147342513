import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import DealsContent from '../../../components/brands/DealsContent';
import FeedContent from '../../../components/brands/FeedContent';
import AboutContent from '../../../components/brands/AboutContent';
import SocialLinksModal from '../../../components/brands/SocialLinksModal';
import ControlModal from '../../../components/brands/ControlModel';
import SubscribeDropdown from '../../../components/brands/SubscribeDropdown';
import { getAllFolders } from '../../../features/stashFolder/folderSlice';
import BrandSinglePageLoader from '../../../skeleton/BrandSinglePageLoader';
import { getFeeds } from '../../../features/feeds/feedsService';
import { getDeals } from '../../../features/deals/dealsService';
import { enqueueSnackbar } from 'notistack';
import Meta from '../../SEO/Meta';
import {
  getSingleBusiness,
  subscribeBusiness,
  unSubscribeBusiness,
} from '../../../features/addBusiness/addBusinessService';
import placeholderImage from '../../../Images/placeholderImage.png';
import Products from '../../products/Products';
import BussinessCard from './BussinessCard';
import StrainCard from '@/common-components/strain/StrainCard';

// Single Brand Page
const BusinessDetailsPage = () => {
  const { id, businessType } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  //   global state
  const loggedInUser = useSelector((state) => state.auth.user._id);
  const userId = useSelector((state) => state.auth.user?._id);

  //   local State
  const [loading, setLoading] = useState(true);
  const [isControlsModalOpen, setIsControlsModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('shop');
  const [isAboutModalOpen, setIsAboutModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [subcribeLoader, setsubcribeLoader] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [Data, setData] = useState({});
  const [Feed, setFeed] = useState([]);
  const [Deals, setDeals] = useState([]);
  const [StrainData, setStrainData] = useState([]);
  const [ProductsData, setProductsData] = useState([]);
  const [BrandData, setBrandData] = useState([]);

  const sectionsRefs = {
    shop: useRef(null),
    strains: useRef(null),
    deals: useRef(null),
    feed: useRef(null),
    retailers: useRef(null),
  };

  const [tabVisibility, setTabVisibility] = useState({
    shop: true,
    strains: true,
    deals: true,
    feed: true,
    retailers: true,
    about: true,
  });

  const defaultTabOrder = [
    'shop',
    'strains',
    'deals',
    'feed',
    'retailers',
    'about',
  ];
  const [tabOrder, setTabOrder] = useState(defaultTabOrder);

  useEffect(() => {
    const savedVisibility = localStorage.getItem(
      `${window.location.origin}/myData-${id}`
    );
    if (savedVisibility) {
      const visibility = JSON.parse(savedVisibility);
      // Ensure 'products' key is replaced by 'shop'
      if (visibility.products !== undefined) {
        visibility.shop = visibility.products;
        delete visibility.products;
      }
      visibility.strains = visibility.strains ?? true;
      setTabVisibility(visibility);
    }

    const savedOrder = JSON.parse(localStorage.getItem(`tabOrder-${id}`));
    if (savedOrder) {
      setTabOrder(savedOrder);
    } else {
      setTabOrder(defaultTabOrder);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    const storageListener = () => {
      const savedOrder = JSON.parse(localStorage.getItem(`tabOrder-${id}`));
      if (savedOrder) {
        setTabOrder(savedOrder);
      }
    };
    window.addEventListener('storage', storageListener);
    return () => window.removeEventListener('storage', storageListener);
  }, [id]);

  useEffect(() => {
    const storageKey = `${window.location.origin}/myData-${id}`;
    localStorage.setItem(storageKey, JSON.stringify(tabVisibility));
  }, [tabVisibility, id]);

  const toggleTabVisibility = (tab) => {
    setTabVisibility((prev) => ({ ...prev, [tab]: !prev[tab] }));
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    const handleScroll = () => {
      let foundSection = false;
      Object.entries(sectionsRefs).forEach(([key, ref]) => {
        if (ref.current) {
          const rect = ref.current.getBoundingClientRect();
          if (
            rect.top <= window.innerHeight / 2 &&
            rect.bottom >= window.innerHeight / 2 &&
            !foundSection
          ) {
            setActiveTab(key);
            foundSection = true;
          }
        }
      });
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const linksForModal = [
    { id: 'email', link: Data?.email, platform: 'email' },
    ...(Data?.socialLinks
      ? Data?.socialLinks.map((link) => ({
          ...link,
          id: link?._id,
          platform: link?.platform,
        }))
      : []),
  ];

  const websiteLink = linksForModal.find((item) => item.platform === 'website');

  const handleSubscribe = async (businessType, businessId) => {
    if (!isSubscribed) {
      try {
        const res = await subscribeBusiness(businessType, businessId);
        if (res) {
          setIsUpdate(!isUpdate);
          enqueueSnackbar(res?.message, { variant: 'success' });
        }
      } catch (error) {
        enqueueSnackbar(error?.message, { variant: 'error' });
      }
    }
  };

  const handleUnsubscribe = async (businessType, businessId) => {
    try {
      const res = await unSubscribeBusiness(businessType, businessId);
      if (res) {
        setIsUpdate(!isUpdate);
        enqueueSnackbar(res?.message, { variant: 'success' });
      }
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: 'error' });
    }
  };

  const isOwner = Data?.createdby === loggedInUser;

  const isSubscribed = useMemo(
    () => Data?.subscribers?.find((item) => item === loggedInUser),
    [Data, loggedInUser]
  );

  const getData = useCallback(async () => {
    try {
      const res = await getSingleBusiness(businessType, id);
      const feed = await getFeeds();
      const deals = await getDeals();
      if (res) {
        setLoading(false);
        setProductsData(res?.products);
        setStrainData(res?.strains);
        setData(res?.business);
        setBrandData(res?.brands);
        setDeals(deals);
        setFeed(feed);
      }
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(error.message, { variant: 'error' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdate, id]);
  useLayoutEffect(() => {
    getData(id);

    return () => {};
  }, [getData, id, businessType, isUpdate]);

  const handleUpdate = () => {
    setIsUpdate(!isUpdate);
  };

  useEffect(() => {
    let isSubscribe = true;
    if (isSubscribe) {
      dispatch(getAllFolders());
    }
    return () => (isSubscribe = false);
  }, [dispatch, isUpdate]);

  function checkDataAvailability(type, data) {
    const isAllDataEmpty = data.every((item) => item.data.length === 0);
    if (isAllDataEmpty) {
      return `No ${type} available`;
    } else {
      return '';
    }
  }

  return (
    <>
      {loading && <BrandSinglePageLoader />}
      {!loading && (
        <>
          <Meta title={Data?.title} siteLink={'/Galeto'} />

          <div className=" flex flex-col gap-3 w-full md:w-[90%] lg:w-[80%] mx-auto px-2 pb-20 ">
            <div className="flex justify-center  items-center   ">
              <div
                className="bg-cover bg-center xl:rounded-xl w-full rounded-xl h-[15vh] xl:h-[50vh] "
                style={{
                  backgroundImage: `url(${
                    Data?.coverImg ? Data?.coverImg?.url : placeholderImage
                  })`,
                  backgroundRepeat: 'no-repeat',
                }}
              ></div>
            </div>

            <div className="flex justify-start items-center text-white gap-4 ">
              <div className="flex justify-start items-center ">
                {Data && Data.profileImg?.url && (
                  <img
                    src={
                      Data.profileImg ? Data.profileImg?.url : placeholderImage
                    }
                    alt={`${Data?.title} profile`}
                    className="w-20 h-20 rounded-full "
                  />
                )}
              </div>
              <div className="flex flex-col justify-center items-start ">
                <div className="flex justify-start items-start">
                  <h1 className="text-2xl xl:text-3xl font-bold whitespace-nowrap">
                    {Data?.title}
                  </h1>
                </div>
                <div className="flex justify-start items-start pt-1">
                  <span className="text-sm whitespace-nowrap text-gray-500">
                    {Data?.subscribersCount} subscribers
                  </span>
                </div>
                <div
                  onClick={openModal}
                  className="truncate w-60 flex items-center"
                >
                  <span className="text-gray-300 whitespace-nowrap truncate text-sm">
                    {Data?.aboutUs}
                  </span>
                  <span className="text-lg text-gray-300">{'>'}</span>
                </div>

                <div className="flex justify-start items-center pt-1">
                  <a
                    href={websiteLink?.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-gray-300 hover:text-gray-500 overflow-hidden text-sm pb-2"
                  >
                    {websiteLink?.link}
                  </a>

                  <button
                    onClick={openModal}
                    className="md:pt-0.5 text-white font-medium text-sm pb-2"
                  >
                    {linksForModal?.length} More Links
                  </button>
                </div>
                <div className="w-full hidden  md:flex md:justify-center md:items-center gap-5 ">
                  <SubscribeDropdown
                    OnUpdate={handleUpdate}
                    loader={subcribeLoader}
                    isSubscribed={isSubscribed}
                    onSubscribe={() =>
                      handleSubscribe(Data?.PrimaryBussiness, Data?._id)
                    }
                    onUnsubscribe={() =>
                      handleUnsubscribe(Data?.PrimaryBussiness, Data?._id)
                    }
                    loggedInUser={userId}
                    businessType={Data?.PrimaryBussiness}
                    isNotificationEnbale={Data?.notificationSubscribers}
                    businessId={Data?._id}
                  />

                  <button
                    onClick={() =>
                      navigate(`/join/${Data._id}/${Data?.PrimaryBussiness}`)
                    }
                    className="rounded-3xl w-full h-full py-2.5 px-10 text-center text-sm font-semibold flex justify-center items-center bg-[#262626] hover:bg-[#3f3f3f] text-white "
                  >
                    Join
                  </button>
                </div>

                {/* Modal for the Twitter Link */}
                <SocialLinksModal
                  isOpen={isModalOpen}
                  onClose={closeModal}
                  aboutUs={Data?.aboutUs}
                  socialLinks={linksForModal}
                  currentBusiness={Data}
                />
              </div>
            </div>
            <div className=" w-full 0   flex justify-center items-center gap-2 md:hidden ">
              <SubscribeDropdown
                OnUpdate={handleUpdate}
                loader={subcribeLoader}
                isSubscribed={isSubscribed}
                onSubscribe={() =>
                  handleSubscribe(Data?.PrimaryBussiness, Data?._id)
                }
                onUnsubscribe={() =>
                  handleUnsubscribe(Data?.PrimaryBussiness, Data?._id)
                }
                loggedInUser={userId}
                businessType={Data?.PrimaryBussiness}
                isNotificationEnbale={Data?.notificationSubscribers}
                businessId={Data?._id}
              />
              <button
                onClick={() =>
                  navigate(`/join/${Data._id}/${Data?.PrimaryBussiness}`)
                }
                className="rounded-3xl w-full h-full py-2.5  text-center text-sm font-semibold flex justify-center items-center bg-[#262626] hover:bg-[#3f3f3f] text-white "
              >
                Join
              </button>
            </div>

            {/* Tabs Section */}
            <div className="sticky top-14 md:top-16 lg:top-20 z-10 bg-[#0f0f0f] flex justify-start items-center text-white space-x-4 overflow-x-auto scrollbar-hide  lg:text-lg">
              {tabOrder
                .filter((tab) => tabVisibility[tab])
                .map((tab) => (
                  <button
                    key={tab}
                    ref={sectionsRefs[tab]}
                    onClick={() => {
                      if (tab === 'about') {
                        setIsAboutModalOpen(true);
                        setActiveTab(tab);
                      } else {
                        sectionsRefs[tab]?.current?.scrollIntoView({
                          behavior: 'smooth',
                        });
                        setActiveTab(tab);
                      }
                    }}
                    className={`pb-2 ${
                      activeTab === tab
                        ? 'border-b-4 border-primary font-bold'
                        : ''
                    }`}
                  >
                    {tab.charAt(0).toUpperCase() + tab.slice(1)}
                  </button>
                ))}

              <div className="flex justify-start items-start w-full md:w-80 pr-2 pb-2 md:pl-40">
                {isOwner && (
                  <button
                    onClick={() => setIsControlsModalOpen(true)}
                    className="bg-[#2c2c2c] text-white px-2 py-1 rounded-md"
                  >
                    Controls
                  </button>
                )}
              </div>
            </div>

            {tabOrder
              .filter((tab) => tabVisibility[tab])
              .map((tab) => (
                <div
                  id={tab}
                  ref={sectionsRefs[tab]}
                  key={tab}
                  className="pb-4 "
                >
                  <span className="text-gray-300 text-2xl font-bold">
                    {tab === 'about'
                      ? ' '
                      : tab.charAt(0).toUpperCase() + tab.slice(1)}
                  </span>
                  {tab === 'shop' && ProductsData?.length > 0 ? (
                    ProductsData.map((category, index) => (
                      <div key={index} className=" p-2 md:p-5">
                        <div className="flex justify-between items-center px-2 pb-2">
                          <h2 className="text-xl font-bold text-white">
                            {category?.type}
                          </h2>
                          <Link
                            to={`/products/${Data._id}/${category?.type}`}
                            className="text-sm ml-2 text-[#62AF41]"
                          >
                            View All
                          </Link>
                        </div>
                        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-4">
                          {category?.data?.map((product, index) => (
                            <Products
                              key={index}
                              itemId={product._id}
                              title={product.title}
                              price={product.price}
                              thc_percentage={product.thc_percentage}
                              cbd_percentage={product.cbd_percentage}
                              brand={product.brand}
                              strainId={product.strain}
                              category={product.category}
                              weight={product.weight}
                              productImg={product.productImg?.url}
                            />
                          ))}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="flex justify-start items-center text-gray-300 text-md pl-5 md:pl-20">
                      {tab === 'shop' &&
                        checkDataAvailability('product', ProductsData)}
                    </div>
                  )}

                  {StrainData?.map((category, index) => (
                    <>
                      {tab === 'strains' && category?.data?.length > 0 && (
                        <div key={index} className=" p-2 md:p-5">
                          <div className="flex justify-between items-center px-2 pb-2">
                            <h2 className="text-xl font-bold text-white">
                              {category?.type}
                            </h2>
                          </div>
                          <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-4 p-2 md:p-5">
                            {category?.data?.map((strain) => (
                              <StrainCard
                                itemType="strain"
                                key={strain._id}
                                id={strain._id}
                                title={strain.title ?? 'Not Found'}
                                father={strain?.father?.title ?? 'Not Found'}
                                mother={strain?.mother?.title ?? 'Not Found'}
                                type={strain?.type}
                                imageUrl={strain?.strainImg?.url}
                                business={strain?.business?.id}
                                onUpdate={() => setIsUpdate(!isUpdate)}
                              />
                            ))}
                          </div>
                        </div>
                      )}
                    </>
                  ))}
                  <div className="flex justify-start items-center text-gray-300 text-md pl-5 md:pl-20">
                    {tab === 'strains' &&
                      checkDataAvailability('strains', StrainData)}
                  </div>

                  {tab === 'deals' && Deals.length > 0 && (
                    <DealsContent deals={Deals} />
                  )}
                  {tab === 'feed' && Feed.length > 0 && (
                    <FeedContent announcements={Feed} />
                  )}
                  {tab === 'retailers' && BrandData?.length > 0 ? (
                    <div className="grid xs:grid-cols-1 gap-4 md:grid-cols-1 lg:grid-cols-3 px-5 py-2">
                      {BrandData?.map((brand, index) => (
                        <BussinessCard
                          key={index}
                          loading={false}
                          Data={brand}
                          isUpdate={isUpdate}
                          OnUpdate={handleUpdate}
                        />
                      ))}
                    </div>
                  ) : (
                    <div className="flex justify-start items-center text-gray-300 text-md pl-5 md:pl-20">
                      {tab === 'retailers' && 'No retailers available.'}
                    </div>
                  )}
                </div>
              ))}

            <ControlModal
              isOpen={isControlsModalOpen}
              onRequestClose={() => setIsControlsModalOpen(false)}
              tabVisibility={tabVisibility}
              toggleTabVisibility={toggleTabVisibility}
              businessId={id}
            />

            {isAboutModalOpen && (
              <AboutContent
                currentBrand={Data}
                onClose={() => setIsAboutModalOpen(false)}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default BusinessDetailsPage;
