import React from 'react'
import { Outlet, Navigate } from 'react-router-dom'


const Protected = () => {
    const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem('user')) : null;
    
   
  return (
    user?.role !=='admin' && user?.role !=='breeder' && user?.role !== 'shopowner' ? (

      <Navigate to='/login' />
      
      ):(
        
        <Outlet/>
    )
  )
}

export default Protected