import { Suspense, lazy } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import AllStrains from "./pages/AllStrains";
import StrainPages from "./pages/StrainPages";
import ReviewDetail from './components/StrainDetail/ReviewDetail';
import ReviewProductDetail from './components/productDetails/ReviewDetail';
import AllBreeder from "./pages/AllBreeder";
import Allbrand from "./pages/AllBrand";
import AllGrower from "./pages/AllGrower";
import AllDispensary from "./pages/AllDispensary";
import AllSocialClub from "./pages/AllSocialClub";
import AllHashMaker from "./pages/AllHashMaker";
import AllDelivery from "./pages/AllDelivery";
import AllSeedBank from "./pages/AllSeedBank";
import AllHempShop from "./pages/AllHempShop";
import AllGrowShop from "./pages/AllGrowShop";
import AllProduct from "./pages/AllProduct";
import ProductCategoryBrand from './pages/ProductCategoryBrand';
import People from "./pages/People";
import Shop from "./pages/Shop";
import AddToCart from "./pages/AddToCart";
import StashPages from "./pages/StashPages";
import Notifications from "./pages/Notifications";
import Profile from "./pages/Profile";
import Subscription from "./pages/Subscription";
import SessionsReview from "./pages/SessionsReview";
import MainLayout from "./components/MainLayout/MainLayout";
import SearchProvider from "./components/context/searchContext";
import Search from "./pages/Search";
import Protected from "./components/Layout/Protected";
import PageNotFound from "./pages/PageNotFound";
import Discover from "./pages/Discover";
import Trending from "./pages/Trending";
import Doctor from "./pages/Doctor";
import Articles from "./pages/Articles";
import Artist from "./pages/Artist";
import Event from "./pages/Event";
import Deal from "./pages/Deal";
import FeedBack from "./pages/FeedBack";
import ForYou from "./pages/ForYou";
import AddToStash from "./pages/AddToStash";
import AllSubBrand from "./pages/AllSubBrand";
import Sessions from "./pages/Sessions";
import SessionsState from "./pages/SessionsState";
import Conversations from "./pages/Conversations";
import Join from "./pages/Join";
import BusinessDetailsPage from "@/components/CommonComponent/AddBusinesses/BusinessDetailsPage";
import ProductPage from "@/pages/ProductPages";
import Home from "@/pages/Home";
import MaryAiSearch from "@/pages/MaryAiSearch";

const Dashboard = lazy(() => import("./pages/Dashboard/Dashboard"));
const UserList = lazy(() => import("./pages/Dashboard/UserList"));
const StrainList = lazy(() => import("./pages/Dashboard/StrainList"));
const AddStrain = lazy(() => import("./pages/Dashboard/AddStrain"));
const AddStrainViaUpload = lazy(() => import("./pages/Dashboard/AddStrainViaUpload"));
const AddBussinessViaUpload = lazy(() => import("./pages/Dashboard/AddBussinessViaUpload"));
const AddBreeder = lazy(() => import("./pages/Dashboard/AddBreeder"));
const DealList = lazy(() => import("./pages/Dashboard/DealList"));
const AddDeal = lazy(() => import("./pages/Dashboard/AddDeal"));
const FeedList = lazy(() => import("./pages/Dashboard/FeedList"));
const AddFeed = lazy(() => import("./pages/Dashboard/AddFeed"));
const BrandList = lazy(() => import("./pages/Dashboard/BrandList"));
const AddBusiness = lazy(() => import("./pages/Dashboard/AddBussiness"));
const UpdateBussiness = lazy(() => import("./pages/Dashboard/UpdateBussiness"));
const AddGrower = lazy(() => import("./pages/Dashboard/AddGrower"));
const AddDispensary = lazy(() => import("./pages/Dashboard/AddDispensary"));
const AddSocialClub = lazy(() => import("./pages/Dashboard/AddSocialClub"));
const AddHashMaker = lazy(() => import("./pages/Dashboard/AddHashMaker"));
const AddDelivery = lazy(() => import("./pages/Dashboard/AddDelivery"));
const AddSeedBank = lazy(() => import("./pages/Dashboard/AddSeedBank"));
const AddHempShop = lazy(() => import("./pages/Dashboard/AddHempShop"));
const AddGrowShop = lazy(() => import("./pages/Dashboard/AddGrowShop"));
const ProductList = lazy(() => import("./pages/Dashboard/ProductList"));
const AddProduct = lazy(() => import("./pages/Dashboard/AddProduct"));
const OrderList = lazy(() => import("./pages/Dashboard/OrderList"));
const Enquiry = lazy(() => import("./pages/Dashboard/Enquiry"));
const Register = lazy(() => import("./pages/Dashboard/Register"));
const Login = lazy(() => import("./pages/Dashboard/Login"));
const ForgotPassword = lazy(() => import("./pages/Dashboard/ForgotPassword"));
const ResetPassword = lazy(() => import("./pages/Dashboard/ResetPassword"));

function App() {
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <BrowserRouter basename={process.env.APP_NAME}>
          <SearchProvider>
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route index element={<Home />} />
                <Route path="/strains" element={<AllStrains />} />
                <Route path="/strain/:id" element={<StrainPages />} />
                <Route path="/product/:id" element={<ProductPage />} />
                <Route path="/strain-detail/:id/review-detail" element={<ReviewDetail />} />
                <Route path="/product-detail/:id/review-detail" element={<ReviewProductDetail />} />
                <Route path="/brand-detail/:id/:businessType" element={<BusinessDetailsPage />} />
                <Route path="/breeder-detail/:id/:businessType" element={<BusinessDetailsPage />} />
                <Route path="/grower-detail/:id/:businessType" element={<BusinessDetailsPage />} />
                <Route path="/dispensary-detail/:id/:businessType" element={<BusinessDetailsPage />} />
                <Route path="/socialClub-detail/:id/:businessType" element={<BusinessDetailsPage />} />
                <Route path="/hashMaker-detail/:id/:businessType" element={<BusinessDetailsPage />} />
                <Route path="/delivery-detail/:id/:businessType" element={<BusinessDetailsPage />} />
                <Route path="/seedBank-detail/:id/:businessType" element={<BusinessDetailsPage />} />
                <Route path="/hempShop-detail/:id/:businessType" element={<BusinessDetailsPage />} />
                <Route path="/growShop-detail/:id/:businessType" element={<BusinessDetailsPage />} />
                <Route path="/Breeder" element={<AllBreeder />} />
                <Route path="/Brand" element={<Allbrand />} />
                <Route path="/people" element={<People />} />
                <Route path="/Shop" element={<Shop />} />
                <Route path="/cart" element={<AddToCart />} />
                <Route path="/stash" element={<AddToStash />} />
                <Route path="/allSubscribedBrand" element={<AllSubBrand />} />
                <Route path="/search" element={<Search />} />
                <Route path="/ai-search" element={<MaryAiSearch />} />

                <Route path="/discover" element={<Discover />} />
                <Route path="/foryou" element={<ForYou />} />
                <Route path="/trending" element={<Trending />} />
                <Route path="/products" element={<AllProduct />} />
                <Route path="/products/:brandId/:categoryName" element={<ProductCategoryBrand />} />
                <Route path="/Grower" element={<AllGrower />} />
                <Route path="/Dispensary" element={<AllDispensary />} />
                <Route path="/Socialclub" element={<AllSocialClub />} />
                <Route path="/HashMaker" element={<AllHashMaker />} />
                <Route path="/Delivery" element={<AllDelivery />} />
                <Route path="/Seedbank" element={<AllSeedBank />} />
                <Route path="/Hempshop" element={<AllHempShop />} />
                <Route path="/Growshops" element={<AllGrowShop />} />
                <Route path="/doctors" element={<Doctor />} />
                <Route path="/events" element={<Event />} />
                <Route path="/articles" element={<Articles />} />
                <Route path="/artists" element={<Artist />} />
                <Route path="/deals" element={<Deal />} />
                <Route path="/feedback" element={<FeedBack />} />
                <Route path="/stash-details/:id" element={<StashPages />} />
                <Route path="/notifications" element={<Notifications />} />
                <Route path="/sessions" element={<Sessions />} />
                <Route path="/sessions-state" element={<SessionsState />} />
                <Route path="/answers" element={<Conversations />} />
                <Route path="/join/:id/:businessType" element={<Join />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/subscription" element={<Subscription />} />
                <Route path="/sessions-review" element={<SessionsReview />} />
              </Route >
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Register />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route element={<Protected />}>
                <Route path="/admin" element={<MainLayout />}>
                  <Route index element={<Dashboard />} />
                  <Route path="user-list" element={<UserList />} />
                  <Route path="strain-list" element={<StrainList />} />
                  <Route path="strain-add" element={<AddStrain />} />
                  <Route path="strain-add/:id" element={<AddStrain />} />
                  <Route path="strain-upload-via-csv" element={<AddStrainViaUpload />} />
                  <Route path="breeder-add" element={<AddBreeder />} />
                  <Route path="breeder-add/:id" element={<AddBreeder />} />
                  <Route path="deal-list" element={<DealList />} />
                  <Route path="deal-add" element={<AddDeal />} />
                  <Route path="deal-add/:id" element={<AddDeal />} />
                  <Route path="feed-list" element={<FeedList />} />
                  <Route path="feed-add" element={<AddFeed />} />
                  <Route path="feed-add/:id" element={<AddFeed />} />
                  < Route path="business-upload-via-csv" element={< AddBussinessViaUpload />} />
                  < Route path="add-business" element={< AddBusiness />} />
                  < Route path="update-business/:bussinessType/:id" element={< UpdateBussiness />} />
                  < Route path="business-list" element={< BrandList />} />
                  < Route path="grower-add" element={< AddGrower />} />
                  < Route path="grower-add/:id" element={< AddGrower />} />
                  < Route path="dispensary-add" element={< AddDispensary />} />
                  < Route path="dispensary-add/:id" element={< AddDispensary />} />
                  < Route path="socialClub-add" element={< AddSocialClub />} />
                  < Route path="socialClub-add/:id" element={< AddSocialClub />} />
                  < Route path="hashMaker-add" element={< AddHashMaker />} />
                  < Route path="hashMaker-add/:id" element={< AddHashMaker />} />
                  < Route path="delivery-add" element={< AddDelivery />} />
                  < Route path="delivery-add/:id" element={< AddDelivery />} />
                  < Route path="seedBank-add" element={< AddSeedBank />} />
                  < Route path="seedBank-add/:id" element={< AddSeedBank />} />
                  < Route path="hempShop-add" element={< AddHempShop />} />
                  < Route path="hempShop-add/:id" element={< AddHempShop />} />
                  < Route path="growShop-add" element={< AddGrowShop />} />
                  < Route path="growShop-add/:id" element={< AddGrowShop />} />
                  < Route path="product-list" element={< ProductList />} />
                  < Route path="product-add" element={< AddProduct />} />
                  < Route path="product-add/:id" element={< AddProduct />} />
                  < Route path="order-list" element={< OrderList />} />
                  < Route path="enquiry" element={< Enquiry />} />
                </Route >
              </Route >
              <Route path="*" element={<PageNotFound />} />
            </Routes >
          </SearchProvider >
        </BrowserRouter >
      </Suspense >
    </>
  );
}

export default App;
