import React, { useEffect, useRef, useState } from 'react';
import { RxCross2 } from 'react-icons/rx';
import { AiOutlineLogout, AiOutlineDashboard } from 'react-icons/ai';
import { CgProfile } from 'react-icons/cg';
import { BiSolidPurchaseTag, BiSolidConversation } from 'react-icons/bi';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { BsFillHandbagFill, BsStars } from 'react-icons/bs';
import { FaCamera } from 'react-icons/fa';
import { IoTimer } from 'react-icons/io5';

import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { resetState } from '../../../features/auth/authSlice';
import SideBarButton from '../../CustomButton/SideBarButton';
import DropButton from '../../CustomButton/DropButton';
import LowserSiderButton from '../../CustomButton/LowserSiderButton';
import TreasureChestIcon from '../../stash/TreasureChestIcon';

const SideBar = ({
  showSidebar,
  handleSidebarToggle,
  setArrowToggle,
  setDropdown,
  arrowToggle,
  dropDown,
}) => {
  // hooks
  const navigate = useNavigate();
  const disptach = useDispatch();
  // globalState
  const userState = useSelector((state) => state.auth.user);

  //   useRefrence
  const buttonRef = useRef(null);
  const dropdownRef = useRef(null);

  //   localState
  const [isLinkUnderlined, setLinkUnderlined] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setArrowToggle(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setArrowToggle]);

  const handleSectionClick = () => {
    setLinkUnderlined(true);
  };
  const userLogout = () => {
    localStorage.removeItem('user');
    disptach(resetState());
    navigate('/');
  };

  const handleRedirect = () => {
    navigate('/profile');
  };

  const userDropDown = () => {
    setArrowToggle((prevSearchState) => !prevSearchState);
  };

  const dropDownHandle = () => {
    setDropdown((prevSideBarState) => !prevSideBarState);
  };
  return (
    <div
      className={`fixed z-50 right-0 top-0 h-screen rounded-bl rounded-tl-2xl w-72 bg-[#0f0f0f] shadow-lg transition-all duration-300 transform overflow-scroll scrollbar-hide ${
        showSidebar ? 'translate-x-0' : 'translate-x-full'
      }`}
    >
      <div className="bg-[#0f0f0f] w-72 h-full float-left items-center relative">
        <div className="flex flex-row px-4 justify-between mt-5">
          <button className="" onClick={handleSidebarToggle}>
            <RxCross2 className=" text-white w-7 h-7" />
          </button>
          <Link to={'/cart'}>
            <button className="mr-3 flex flex-row items-center bg-transparent hover:bg-gray-500 text-gray-300 hover:text-white font-semibold py-2 px-3 border border-transparent rounded-lg ">
              <span className="mr-2">My Cart</span>
              <BsFillHandbagFill size={25} />
            </button>
          </Link>
        </div>

        {userState ? (
          <>
            <div className="flex justify-between items-center py-4 px-4">
              <span className="capitalize text-gray-300  focus:outline-none  font-bold text-base w-60 py-1.5">
                <button ref={buttonRef} onClick={userDropDown}>
                  <div className="flex justify-start items-center gap-2">
                    <img
                      className="w-8 h-8 md:w-12 md:h-12 border-2 sideBarGradient  rounded-full"
                      src={userState?.userImg?.url}
                      alt="rose"
                    />
                    <span className="text-lg capitalize">
                      {userState?.firstname}
                    </span>
                  </div>

                  <div
                    ref={dropdownRef}
                    className={`z-30 border-gray-500 text-gray-300 absolute  items-center top-32 md:left-4 xs:left-4 capitalize ${
                      arrowToggle ? 'block' : 'hidden'
                    }`}
                  >
                    <ul className="text-gray-300">
                      <li
                        className="p-2 flex flex-row  items-center bg-[#0f0f0f] hover:bg-white hover:text-black border border-grey-500"
                        onClick={handleRedirect}
                      >
                        <CgProfile size={16} className="mr-1" />
                        profile
                      </li>
                      <li
                        className={` flex flex-row  items-center bg-[#0f0f0f] hover:bg-white hover:text-black border border-grey-500 ${
                          userState.role !== 'admin' &&
                          userState.role !== 'breeder' &&
                          userState.role !== 'shopowner'
                            ? 'hidden'
                            : 'block'
                        }`}
                      >
                        {' '}
                        <Link
                          className="p-2 flex flex-row items-center justify-between"
                          to={'/admin'}
                        >
                          <AiOutlineDashboard size={16} className={`mr-1 `} />{' '}
                          Dashboard
                        </Link>
                      </li>
                    </ul>
                  </div>
                </button>
              </span>
              <button
                className="flex flex-row items-center py-1.5 px-4 text-gray-300 rounded-lg bg-[#2c2c2c] hover:bg-[#787777]"
                onClick={userLogout}
              >
                <AiOutlineLogout size={20} className="mr-1" />
                Logout
              </button>
            </div>
            <div className="flex flex-col justify-left ml-6">
              <div className="mb-4 w-60">
                <Link
                  className=" flex justify-between items-start capitalize text-white rounded-lg sideBarGradient font-semibold w-60 py-1.5 cursor-pointer"
                  to={'/stash'}
                >
                  <div className="flex items-center">
                    <TreasureChestIcon size={24} className="ml-2 mr-3" />
                    <span className="">Stash</span>
                  </div>
                </Link>
              </div>
              <SideBarButton
                text={'Purchases'}
                icon={<BiSolidPurchaseTag size={20} className="ml-2 mr-4" />}
              />
              <Link to={'/sessions'}>
                <SideBarButton
                  text={'Sessions'}
                  icon={<IoTimer size={20} className="ml-2 mr-4" />}
                />
              </Link>
              <Link to={'/answers'}>
                <SideBarButton
                  text={'Answers'}
                  icon={
                    <BsStars
                      color="#ffffff"
                      size={20}
                      className="ml-2 mr-4 rotate-[270deg] "
                    />
                  }
                />
              </Link>
            </div>

            <div className="flex justify-start items-center pl-6 my-2">
              <span className="capitalize text-gray-300 border-b-2  focus:outline-none  font-bold text-base py-1.5  ">
                Verify Authenticity
              </span>
              <span className="text-[#62AF41] pl-1 text-sm">coming soon</span>
            </div>

            <div className="w-full flex flex-row items-center justify-center mt-2">
              <div className="relative">
                <input
                  type="text"
                  placeholder="Enter Serial or Scan"
                  className="pl-8 pr-2 py-1 w-60  border  rounded-full outline-none sideBarInputGradient"
                  disabled
                />
                <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                  <FaCamera size={18} />
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-left items-center my-2">
              <span className="capitalize text-gray-300 border-b-2 border-gray-500  focus:outline-none  font-bold text-base w-60 py-1.5  ">
                Business Dashboard
              </span>
            </div>

            <div className=" w-60  font-semibold flex flex-row justify-left items-center mx-6 cursor-pointer">
              <button
                onClick={() => navigate('/subscription')}
                className=" w-full  flex flex-row justify-between items-center  py-2 text-gray-300 "
              >
                List Your Business
              </button>
            </div>

            <div className="flex flex-row justify-start items-center mb-2 ml-6 ">
              <button className="capitalize text-gray-300 hover:underline   focus:outline-none font-semibold text-sm  py-1.5  ">
                <Link>Advertise With us</Link>
              </button>
            </div>
          </>
        ) : (
          <>
            <section className="border-2 m-1" onClick={handleSectionClick}>
              <div className="flex flex-col justify-left items-center my-4">
                <span className="  capitalize text-gray-300 border-b-2  focus:outline-none  font-bold text-base w-60 py-1.5 ">
                  <Link
                    className={`text-[#62AF41]  ${
                      isLinkUnderlined ? 'underline' : ''
                    }`}
                    to={'/signup'}
                  >
                    Sign Up{' '}
                  </Link>
                  for a Personalized Experience
                </span>
              </div>
              <div className="flex flex-col justify-left ml-6">
                <SideBarButton
                  text={'Purchases'}
                  icon={<BiSolidPurchaseTag size={20} className="ml-2 mr-4" />}
                  value={'True'}
                />
                <SideBarButton
                  text={'insights'}
                  icon={<IoTimer size={20} className="ml-2 mr-4" />}
                  value={'True'}
                />
                <SideBarButton
                  text={'track usage'}
                  icon={<BiSolidConversation size={20} className="ml-2 mr-4" />}
                  value={'True'}
                />
                <SideBarButton
                  text={'stash items:'}
                  icon={<TreasureChestIcon size={28} className="ml-2 mr-4" />}
                  url={'/stash'}
                />
              </div>
            </section>
            <div className="flex flex-col justify-left items-center my-2">
              <span className="  capitalize text-gray-300 border-b-2  focus:outline-none  font-bold text-base w-60 py-1.5  ">
                <Link className="text-[#62AF41]" to={'/signup'}>
                  Sign Up{' '}
                </Link>
                for Business solutions
              </span>
            </div>

            <div className=" w-60  font-semibold flex flex-row justify-left items-center mx-6 cursor-pointer">
              <button
                onClick={dropDownHandle}
                className=" w-full  flex flex-row justify-between items-center  py-2 "
              >
                List Your Business
                {dropDown ? (
                  <MdKeyboardArrowUp size={24} className="  float-right " />
                ) : (
                  <MdKeyboardArrowDown size={24} className="  float-right" />
                )}
              </button>
            </div>
            <div className={`${dropDown ? 'block' : 'hidden'}`}>
              <div
                className={`  mt-1 w-60 sideBarGradientDropdown mx-6 rounded-lg`}
              >
                <div className="w-full grid grid-cols-1">
                  <DropButton text={'Breeder'} />
                  <DropButton text={'Hemp Shop'} />
                  <DropButton text={'Brand'} />
                  <DropButton text={'Grow Shop'} />
                  <DropButton text={'Grow'} />
                  <DropButton text={'Doctor'} />
                  <DropButton text={'Dispensary'} />
                  <DropButton text={'Artist'} />
                  <DropButton text={'Social Club'} />
                  <DropButton text={'Event'} />
                  <DropButton text={'Delivery Service'} />
                  <DropButton text={'Journalist/Blog'} />
                  <DropButton text={'seed bank'} />
                  <DropButton text={'other'} />
                </div>
              </div>
            </div>
            <div className="flex flex-row justify-start items-center mb-2 ml-6 ">
              <button className="capitalize text-gray-300 hover:underline   focus:outline-none font-semibold text-sm  py-1.5  ">
                <Link>Advertise With us</Link>
              </button>
            </div>
            <div className="flex flex-col justify-left items-center my-2">
              <span className=" capitalize text-gray-300 border-b-2 border-gray-500  focus:outline-none  font-bold text-base w-60 py-1.5  ">
                Verify Authenticity
              </span>
            </div>

            <div className="w-full flex flex-row items-center justify-center">
              <div className="relative">
                <input
                  type="text"
                  placeholder="Enter Serial or Scan"
                  className="pl-8 pr-2 py-1 w-60  border  rounded-full outline-none sideBarInputGradient"
                />
                <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                  <FaCamera size={18} />
                </div>
              </div>
            </div>
          </>
        )}

        <div className="flex flex-col justify-left items-center mt-4">
          <span className="  capitalize text-gray-300 border-b-2 border-gray-500  focus:outline-none  font-bold text-base w-60 py-1.5  ">
            About Mary
          </span>
        </div>

        <LowserSiderButton text={'Company'} />
        <LowserSiderButton text={'Terms of Use'} />
        <LowserSiderButton text={'Privacy Policy'} />
        <LowserSiderButton text={'Contact Us'} />

        <div className=" border border-gray-500 m-2">
          <span className="flex flex-row justify-center text-xs text-justify m-2 text-gray-300">
            * Statements made on this website have not been evaluated by the
            U.S. Food and Drug Administration. These products are not intended
            to diagnose, treat, cure or prevent any disease. Information
            provided by this website or this company is not a substitute for
            individual medical advice.
          </span>
        </div>
        <div className="my-4 border-t text-gray-300 border-gray-500 text-xs text-center ">
          <span>©️ 2024 Plant Intelligence Research Organization</span>
        </div>
        <div className="xs:pb-20"></div>
      </div>
    </div>
  );
};

export default SideBar;
