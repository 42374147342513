import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AiOutlineDashboard, AiFillHome } from 'react-icons/ai';
import { FaUserAlt } from 'react-icons/fa';
import { TbPlant2 } from 'react-icons/tb';
import { BiListUl } from 'react-icons/bi';
import { BsCartCheck, BsBell } from 'react-icons/bs';
import { MdAdd } from 'react-icons/md';
import { SiBrandfolder } from 'react-icons/si';
import { FiLogOut } from 'react-icons/fi';
import { CiDiscount1 } from 'react-icons/ci';
import { VscPreview } from 'react-icons/vsc';
import { CgDetailsMore, CgProductHunt } from 'react-icons/cg';
import { TfiAnnouncement } from 'react-icons/tfi';
import logo from '@/Images/maryLogo.png';
import { Button, Layout, Menu } from 'antd';
import { useState } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { resetState } from '../../features/auth/authSlice';
const { Header, Sider, Content } = Layout;

const MainLayout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [collapsed, setCollapsed] = useState(false);

  const userState = useSelector((state) => [state.auth.user]);

  const handleMenuClick = (key) => {
    if (key === 'signout') {
      localStorage.removeItem('user');
      dispatch(resetState());
      navigate('/');
    } else if (key === 'add-business') {
      navigate('/admin/add-business');
    } else if (key === 'home') {
      navigate('/');
    } else {
      navigate(key);
    }
  };

  return userState.map((user) => {
    const isAdmin = user?.role === 'admin';
    const isNotShopOwner = user?.role !== 'shopowner';

    return (
      <>
        <Layout className="h-full" key={user?._id}>
          <div className="bg-[#253238]">
            <Sider
              style={{
                overflow: 'scroll',
                height: '100vh',
                position: 'fixed',
                background: 'none',
                left: 0,
                top: 0,
                bottom: 0,
              }}
              trigger={null}
              collapsible
              collapsed={collapsed}
              className="h-screen border-r border-white bg-[#253238] z-10 overflow-y-auto scrollbar-hide"
            >
              <div className="demo-logo-vertical " />
              <Link to="/">
                <img src={logo} className="w-28 " alt="#" />
              </Link>
              <Button
                type="text"
                // icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={() => setCollapsed(!collapsed)}
                className={`text-white HeadButton hidden xs:block ${
                  !collapsed ? 'ml-[35%]' : 'ml-[13%]'
                }`}
                style={{
                  fontSize: '16px',
                  width: 64,
                  height: 64,
                }}
              />
              <div className="flex flex-col my-2 text-center justify-center items-center lg:hidden md:hidden">
                <div className="flex flex-col">
                  <img
                    className="w-10 h-10 border-2 border-white  rounded-full"
                    src={user?.userImg?.url}
                    alt=" "
                  />
                </div>
                <div className="flex flex-col text-white text-base mx-5">
                  <div className=" capitalize">{user?.firstname}</div>
                  <div className=" text-base text-white bg-[#62AF41] border-2 border-white px-2 capitalize">
                    {user?.role}
                  </div>
                </div>
              </div>

              <Menu
                theme="dark"
                mode="inline"
                className="bg-[#253238] text-white text-base h-full"
                defaultSelectedKeys={['']}
                onClick={({ key }) => handleMenuClick(key)}
                // onClick={({ key }) => {
                //   if (key === "signout") {
                //     localStorage.removeItem('user')
                //     dispatch(resetState())
                //     navigate("/")
                //     // window.location.reload()

                //   }
                //   if(key==="home"){
                //     navigate("/")

                //   }
                //   else {
                //     navigate(key)

                //     // window.location.reload()

                //   }
                // }}
                items={[
                  {
                    key: '',
                    icon: (
                      <AiOutlineDashboard className="text-base" size={20} />
                    ),
                    label: 'Dashboard',
                  },

                  isAdmin && {
                    key: 'user',
                    icon: <FaUserAlt className="text-white" size={20} />,
                    label: 'Users',
                    children: [
                      {
                        key: 'user-list',
                        icon: <BiListUl />,
                        label: 'Users List',
                      },
                    ],
                  },
                  isNotShopOwner && {
                    key: 'strain',
                    icon: <TbPlant2 className="text-white " size={20} />,
                    label: 'Strains',
                    children: [
                      {
                        key: 'strain-upload-via-csv',
                        icon: <BiListUl />,
                        label: 'Upload Strain',
                      },
                      {
                        key: 'strain-list',
                        icon: <BiListUl />,
                        label: 'Strains List',
                      },
                      {
                        key: 'strain-add',
                        icon: <MdAdd />,
                        label: 'Add Strain',
                      },
                    ],
                  },

                  isNotShopOwner && {
                    key: 'Deal',
                    icon: <CiDiscount1 className="text-white " size={20} />,
                    label: 'Deals',
                    children: [
                      {
                        key: 'deal-list',
                        icon: <BiListUl />,
                        label: 'Deal List',
                      },
                      {
                        key: 'deal-add',
                        icon: <MdAdd />,
                        label: 'Add deal',
                      },
                    ],
                  },
                  isNotShopOwner && {
                    key: 'Feed',
                    icon: <TfiAnnouncement className="text-white" size={20} />,
                    label: 'Feeds',
                    children: [
                      {
                        key: 'feed-list',
                        icon: <BiListUl />,
                        label: 'Feed List',
                      },
                      {
                        key: 'feed-add',
                        icon: <MdAdd />,
                        label: 'Add feed',
                      },
                    ],
                  },

                  {
                    key: 'Bussiness',
                    icon: <SiBrandfolder className="text-white " size={20} />,
                    label: 'bussiness',
                    children: [
                      {
                        key: 'business-upload-via-csv',
                        icon: <BiListUl />,
                        label: 'upload Business',
                      },
                      {
                        key: 'business-list',
                        icon: <BiListUl />,
                        label: 'Business List',
                      },
                      {
                        key: 'add-business',
                        icon: <MdAdd />,
                        label: 'Add Bussiness',
                      },
                    ],
                  },
                  {
                    key: 'product',
                    icon: <CgProductHunt className="text-white " size={20} />,
                    label: 'Products',
                    children: [
                      {
                        key: 'product-list',
                        icon: <BiListUl />,
                        label: 'Product List',
                      },
                      {
                        key: 'product-add',
                        icon: <MdAdd />,
                        label: 'Add Product',
                      },
                    ],
                  },
                  {
                    key: 'order',
                    icon: <BsCartCheck className="text-white " size={20} />,
                    label: 'Orders',
                    children: [
                      {
                        key: 'order-list',
                        icon: <BiListUl />,
                        label: 'order List',
                      },
                      {
                        key: 'order-detail',
                        icon: <CgDetailsMore />,
                        label: 'Order Detail',
                      },
                    ],
                  },
                  {
                    key: 'enquiry',
                    icon: <VscPreview className="text-white " size={20} />,
                    label: 'Enquiry',
                  },
                  {
                    key: 'home',
                    icon: <AiFillHome className="text-white " size={20} />,
                    className: 'mt-auto',
                    label: 'Home',
                  },
                  {
                    key: 'signout',
                    icon: <FiLogOut className="text-white " size={20} />,
                    className: 'mt-auto',
                    label: 'Logout',
                  },
                ].filter(Boolean)}
              />
            </Sider>
          </div>
          <Layout className="bg-[#253238]">
            <Header
              style={{
                padding: 0,
              }}
              className="bg-[#253238] border-b border-white w-full flex flex-row-reverse justify-between ml-auto z-100"
            >
              <div className="flex flex-row my-2 text-center justify-center items-center xs:hidden ">
                <div className="text-white text-base mx-6 mt-3 ">
                  <BsBell className=" relative text-lg" />
                  <span className="text-center text-sm w-5 h-5 bg-[#62AF41] rounded-full absolute top-2">
                    3
                  </span>
                </div>
                <div className="flex flex-col">
                  <img
                    className="w-10 h-10 border-2 border-white  rounded-full"
                    src={user?.userImg?.url}
                    alt="rose"
                  />
                </div>
                <div className="flex flex-col text-white text-base mx-5">
                  <div className=" capitalize">{user?.firstname}</div>
                  <div className=" text-base text-white bg-[#62AF41] border-2 border-white px-2 capitalize">
                    {user?.role}
                  </div>
                </div>
              </div>
            </Header>
            <Content
              style={{
                overflow: 'initial',
              }}
              className="bg-[#253238] text-white  mt-2"
            >
              <div className="flex flex-row">
                <div className="lg:w-[20%] md:w-[35%] xs:w-[30%] h-full">
                  <ToastContainer
                    position="top-right"
                    autoClose={250}
                    hideProgressBar={false}
                    newestOnTop={true}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    theme="light"
                  />
                </div>

                <Outlet />
              </div>
            </Content>
          </Layout>
        </Layout>
      </>
    );
  });
};
export default MainLayout;
