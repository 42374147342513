import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import authService from "./authService";

const getUserfromLocalStorage = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem("user")) : null

const initialState = {
    user: getUserfromLocalStorage,
    order: [],
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: "",
}

export const login = createAsyncThunk(
    "auth/login",
    async (user, thunkAPI) => {
        try {
            return await authService.login(user)
        } catch (error) {
            if (error.response) {
                // console.log('Error Response Data:', error.response.data);
            }
            console.log(error.response.data.message)
            throw error.response.data.message
        }
    }
)
export const logout = createAsyncThunk(
    "auth/logout",
    async (thunkAPI) => {
        try {
            return await authService.logout()
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)
export const allOrders = createAsyncThunk(
    "order/get-allorder",
    async (thunkAPI) => {
        try {
            return await authService.allOrders()
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const resetState = createAction("Reset_user")
export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(login.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.user = action.payload;
            })
            .addCase(login.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error.message
            })
        builder.addCase(allOrders.pending, (state) => {
            state.isLoading = true;
        })
            .addCase(allOrders.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.order = action.payload;
            })
            .addCase(allOrders.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error
            })
        builder.addCase(logout.pending, (state) => {
            state.isLoading = true;
        })
            .addCase(logout.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.logout = action.payload;
            })
            .addCase(logout.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error
            }).addCase(resetState, (state) => {

                state.user = null;
                state.isError = false;
                state.isLoading = false;
                state.isSuccess = false;
                // Set the 'user' to null when resetState action is dispatched
                // Object.assign(state, initialState);
                // Reset other state properties to their initial values
            });
    }
})

export default authSlice.reducer